import React, { useState, useEffect } from "react";
import { differenceInDays } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "components/common/form/inputs/PhoneInput";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReCaptcha } from "react-recaptcha-v3";
import capitalize from "../../utils/helpers/capitalize";
import QueueDisplay from "./splitflap/QueueDisplay";

const QueueForm = ({
  queue,
  textSequence,
  queueEnabled,
  queueNumber,
  queueCap,
  queueLength,
  locationName,
  locationRef,
  firebase,
  db,
}) => {
  const title = locationName ? capitalize(locationName) : undefined;
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // Track queue numbers by location
  const [queueNumbers, setQueueNumbers] = useState({});
  const currentQueueNumber = locationRef
    ? queueNumbers[locationRef] || queueNumber || 0
    : 0;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  let lastSignedUp = localStorage["queueSubmitted"];

  // Reset form state when location changes
  useEffect(() => {
    setSubmitted(false);
    reset();
  }, [locationRef, reset]);

  // Subscribe to queue number changes for the current location
  useEffect(() => {
    if (!locationRef) return;

    const unsubscribe = db
      .collection("locations")
      .doc(locationRef)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setQueueNumbers((prev) => ({
            ...prev,
            [locationRef]: doc.data().queueNumber || 0,
          }));
        }
      });

    // Cleanup subscription when location changes or component unmounts
    return () => {
      unsubscribe();
    };
  }, [locationRef, db]);

  const onSubmit = async ({ phone }) => {
    if (!locationRef) return;

    setLoading(true);
    try {
      const batch = db.batch();
      const nextQueueNumber = currentQueueNumber + 1;

      batch.update(db.collection("locations").doc(locationRef), {
        queueNumber: firebase.firestore.FieldValue.increment(1),
        queue: firebase.firestore.FieldValue.arrayUnion({
          id: nextQueueNumber,
          date: Date.now(),
          phone,
        }),
      });

      const body =
        (textSequence
          ? textSequence[0]
          : `Thank you for joining the Virtual Queue at ${title}. You should expect several more texts to guide you through this process. If your phone dies (they do that), proceed directly to ${title} to check in, and tell them your patient number in the Virtual Queue.`) +
        ` You are patient #${nextQueueNumber}.`;

      batch.set(db.collection("messages").doc(), {
        to: phone,
        body,
        location: locationRef,
        date: Date.now(),
      });

      await batch.commit();

      // Update the queue number for this specific location
      setQueueNumbers((prev) => ({
        ...prev,
        [locationRef]: nextQueueNumber,
      }));

      setSubmitted(true);
      localStorage["queueSubmitted"] = new Date();
    } catch (error) {
      console.error("Error submitting to queue:", error);
    } finally {
      setLoading(false);
    }
  };

  if (queueLength === undefined) {
    queueLength = 0;
  }

  // Only allow one virtual queue signup a day on production
  if (
    process.env.REACT_APP_FIREBASE_PROJECT_ID === "hlthdsk" &&
    differenceInDays(new Date(), new Date(lastSignedUp)) <= 1
  )
    return null;

  return (
    <>
      {queueEnabled && queueLength < queueCap && (
        <Box sx={{ maxWidth: 420, m: "auto", mt: 2, mb: 2 }}>
          {submitted ? (
            <Box>
              <Typography variant="h6">Success!</Typography>
              <Typography variant="body2">
                You should receive a series of texts to guide you through the
                process. If you have problems receiving texts, please proceed to{" "}
                {locationName}.
              </Typography>
              <List
                dense={true}
                sx={{ listStyleType: "disc", pl: 2, fontSize: 14 }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  You won't receive a text if your phone isn't on.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  You'll have a 30 minute arrival window after receiving that
                  text.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Never use this system if you have an emergency. Dial 911.
                </ListItem>
              </List>
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "You must enter a phone number.",
                }}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    id="customPhone"
                    placeholder="123 456 7890"
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />

              {errors?.phone ? (
                <FormHelperText error>{errors.phone.message}</FormHelperText>
              ) : null}

              <Controller
                name="consent"
                control={control}
                rules={{
                  required: "You must agree to the conditions.",
                }}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    slotProps={{
                      typography: { position: "relative", fontSize: 13, mb: 1 },
                    }}
                    sx={{ alignItems: "flex-start", mt: 1, mb: 1 }}
                    label="I agree to receive customer care messaging from HLTHDSK at the phone number provided above. I understand I will receive several messages designed to decrease my time in the waiting room. Data rates may apply, reply STOP to opt out."
                  />
                )}
              />
              {errors?.consent ? (
                <FormHelperText error sx={{ mt: 0, mb: 1 }}>
                  {errors.consent.message}
                </FormHelperText>
              ) : null}
              <LoadingButton
                loading={loading}
                disabled={loading}
                variant="contained"
                fullWidth
                type="submit"
              >
                Join Virtual Queue
              </LoadingButton>

              <QueueDisplay
                queue={queue}
                compact={true}
                showClock={false}
                showHeader={false}
              />

              <Typography
                variant="body2"
                sx={{ textAlign: "center", mt: 2, mb: -1 }}
              >
                <Link href="/privacy-policy">Privacy Policy</Link> |{" "}
                <Link href="/terms-of-use">Terms of Use</Link>
              </Typography>

              <ReCaptcha
                sitekey="6LdFylEaAAAAAFxT6Keio4PQU5sg1jhvn7BE5mPq"
                action="submit"
              />
            </form>
          )}
        </Box>
      )}
    </>
  );
};

export default QueueForm;
