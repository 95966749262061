import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  TextField,
  FormHelperText,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import firebaseApp from "services/firebase";
import { useAuth } from "hooks/useAuth";

const LoginForm = ({ loginFormOpen, setLoginFormOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading: authLoading } = useAuth();
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [loading, setLoading] = useState(false);

  // Display message from navigation state if it exists
  useEffect(() => {
    if (location.state?.message) {
      setFirebaseErrors(location.state.message);
    }
  }, [location.state]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async ({ email, password }) => {
    setLoading(true);
    setFirebaseErrors("");

    try {
      await firebaseApp.auth().signInWithEmailAndPassword(email, password);

      // Get returnUrl from query parameters
      const params = new URLSearchParams(location.search);
      const returnUrl = params.get("returnUrl");

      // Validate returnUrl to prevent open redirect vulnerabilities
      const isValidReturnUrl = (url) => {
        try {
          const parsedUrl = new URL(url, window.location.origin);
          return parsedUrl.origin === window.location.origin;
        } catch {
          return false;
        }
      };

      // Navigate to returnUrl if it exists and is valid, otherwise to dashboard
      if (returnUrl && isValidReturnUrl(returnUrl)) {
        navigate(returnUrl);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      // Map Firebase error codes to user-friendly messages
      const errorMessages = {
        "auth/invalid-credential":
          "Your email address or password is incorrect. Please try again.",
        "auth/user-disabled":
          "This account has been disabled. Please contact support.",
        "auth/user-not-found":
          "No account found with this email address. Please check your email or sign up.",
        "auth/too-many-requests":
          "Too many failed login attempts. Please try again later or reset your password.",
        "auth/network-request-failed":
          "Unable to connect to the server. Please check your internet connection.",
      };

      const errorMessage =
        errorMessages[error.code] ||
        "An unexpected error occurred. Please try again.";
      setFirebaseErrors(errorMessage);

      // Log the actual error for debugging (but don't show to user)
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!loginFormOpen) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Already have an account?
        </Typography>
        <Button
          variant="contained"
          onClick={() => setLoginFormOpen(true)}
          sx={{ mb: 1 }}
        >
          Log In
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        defaultValue={""}
        rules={{
          required: "Email is required.",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address.",
          },
        }}
        render={({ field }) => (
          <TextField
            id="email"
            label="Email"
            type="email"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.email}
            helperText={errors?.email?.message}
            sx={{ pb: 2 }}
            {...field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue={""}
        rules={{
          required: "Password is required.",
        }}
        render={({ field }) => (
          <TextField
            id="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
            sx={{ pb: 1 }}
            {...field}
          />
        )}
      />

      {firebaseErrors && (
        <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
      )}

      <LoadingButton
        type="submit"
        loading={loading || authLoading}
        disabled={loading || authLoading}
        variant="contained"
        fullWidth
        size="large"
        sx={{ mt: 2, mb: 3 }}
      >
        Log In
      </LoadingButton>
    </form>
  );
};

export default LoginForm;
