import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { AuthContext } from "context/Auth";
import { FaBars } from "react-icons/fa";
import NavListItem from "../NavListItem";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DescriptionRounded,
  HelpRounded,
  LocalHospitalRounded,
  MedicationRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  Typography,
} from "@mui/material";

const MenuButton = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <IconButton onClick={() => setShowMenu(true)}>
        <MenuIcon />
      </IconButton>
      <MainMenu open={showMenu} close={() => setShowMenu(false)} />
    </>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  ...theme.mixins.toolbar,
}));

const MainMenu = ({ open, close }) => {
  const { user } = useContext(AuthContext);

  return (
    <Drawer open={open} onClose={close}>
      <DrawerHeader sx={{ alignItems: "center" }}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={close}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>

      <Divider />

      <List sx={{ width: 300 }}>
        <NavListItem
          icon={<LocalHospitalRounded />}
          text="Add a Facility"
          link="/register"
        />

        <NavListItem
          icon={<HelpRounded />}
          text="Learn More"
          link="https://md3c.com"
        />

        <NavListItem
          icon={<MedicationRounded />}
          text="Medication Discounts"
          link="https://interlockhealth.com/healthcard-basic/"
        />

        <NavListItem
          icon={<DescriptionRounded />}
          text="Terms of Use"
          link="https://hlthdsk.com/terms-of-use"
        />

        <NavListItem
          icon={<VerifiedUserRounded />}
          text="Privacy Policy"
          link="https://hlthdsk.com/privacy-policy"
        />
      </List>

      <Box sx={{ position: "absolute", bottom: 0, p: 3 }}>
        <Typography variant="body2">
          This site is protected by reCAPTCHA and the Google{" "}
          <Link href="https://policies.google.com/privacy">Privacy Policy</Link>{" "}
          and{" "}
          <Link href="https://policies.google.com/terms">Terms of Service</Link>{" "}
          apply.
        </Typography>
      </Box>
    </Drawer>
  );
};

export default MenuButton;
