import React, { useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { loadReCaptcha } from "react-recaptcha-v3";
import { AuthProvider } from "context/Auth";
import { MapboxCacheProvider } from "hooks/useMapboxCache";
import { AppRoutes } from "routes/AppRoutes";
import { muiTheme } from "config/theme";
import { initSentry } from "config/sentry";
import theme from "utils/helpers/theme";

// Initialize Sentry
initSentry();

function App() {
  useEffect(() => {
    loadReCaptcha("6LdFylEaAAAAAFxT6Keio4PQU5sg1jhvn7BE5mPq", () => {});
  }, []);

  const api_regex = /^\/api\/.*/;
  if (api_regex.test(window.location.pathname)) {
    return <div />;
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <MapboxCacheProvider>
            <BrowserRouter>
              <div className="App">
                <AppRoutes />
              </div>
            </BrowserRouter>
          </MapboxCacheProvider>
        </AuthProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
