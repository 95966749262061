import React, { useState } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
  Paper,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import {
  Help as HelpIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import { useAuth } from "hooks/useAuth";

const AdminTestBar = ({ onAssistantChange }) => {
  const { userData } = useAuth();

  const assistants = [
    {
      id: process.env.REACT_APP_ASSISTANT_INDIVIDUAL,
      name: "Individual Assistant",
    },
    { id: process.env.REACT_APP_ASSISTANT_ONE, name: "Assistant One" },
    { id: process.env.REACT_APP_ASSISTANT_TWO, name: "Assistant Two" },
    { id: process.env.REACT_APP_ASSISTANT_THREE, name: "Assistant Three" },
    {
      id: process.env.REACT_APP_ASSISTANT_PROFESSIONAL,
      name: "Professional Assistant",
    },
    { id: process.env.REACT_APP_ASSISTANT_PRO_ONE, name: "Pro Assistant One" },
    { id: process.env.REACT_APP_ASSISTANT_PRO_TWO, name: "Pro Assistant Two" },
    {
      id: process.env.REACT_APP_ASSISTANT_PRO_THREE,
      name: "Pro Assistant Three",
    },
  ];

  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0].id);
  const [inputMode, setInputMode] = useState("preset");

  const handleModeSwitch = () => {
    setInputMode(inputMode === "preset" ? "custom" : "preset");
    setSelectedAssistant("");
    onAssistantChange("");
  };

  const handleAssistantChange = (value) => {
    setSelectedAssistant(value);
    onAssistantChange(value);
  };

  const getDefaultAssistant = () => {
    if (userData?.role === "patient") {
      return process.env.REACT_APP_ASSISTANT_INDIVIDUAL;
    } else if (userData?.role === "professional") {
      return process.env.REACT_APP_ASSISTANT_PROFESSIONAL;
    }
    return assistants[0].id; // fallback to first assistant
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        bottom: 60,
        left: 16,
        width: 300,
        bgcolor: "grey.900",
        color: "common.white",
        zIndex: 1300,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Chip
            icon={<AssignmentIcon sx={{ fontSize: 16 }} />}
            label="Admin Testing"
            size="small"
            sx={{
              bgcolor: "primary.dark",
              color: "common.white",
              "& .MuiChip-icon": {
                color: "common.white",
              },
            }}
          />
          <Tooltip
            title="Admin testing mode allows you to test different OpenAI assistants. Select from presets or enter a custom assistant ID."
            arrow
          >
            <IconButton size="small" sx={{ color: "grey.400" }}>
              <HelpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Current Assistant Display */}
        <Typography variant="caption" sx={{ color: "grey.400" }}>
          Currently using: {selectedAssistant || getDefaultAssistant()}
        </Typography>

        {/* Assistant Selection */}
        {inputMode === "preset" ? (
          <FormControl size="small" fullWidth>
            <Select
              value={selectedAssistant}
              onChange={(e) => handleAssistantChange(e.target.value)}
              sx={{
                height: "32px",
                color: "common.white",
                bgcolor: "grey.800",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey.700",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey.600",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
              }}
            >
              {assistants.map((assistant) => (
                <MenuItem key={assistant.id} value={assistant.id}>
                  {`${assistant.name} (${assistant.id})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            size="small"
            fullWidth
            value={selectedAssistant}
            onChange={(e) => handleAssistantChange(e.target.value)}
            placeholder="Enter assistant ID"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "32px",
                color: "common.white",
                bgcolor: "grey.800",
                "& fieldset": {
                  borderColor: "grey.700",
                },
                "&:hover fieldset": {
                  borderColor: "grey.600",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "primary.main",
                },
              },
            }}
          />
        )}

        {/* Mode Switch */}
        <Typography
          variant="caption"
          component="a"
          onClick={handleModeSwitch}
          sx={{
            color: "primary.light",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          {inputMode === "preset"
            ? "Use custom assistant ID"
            : "Use preset assistants"}
        </Typography>
      </Box>
    </Paper>
  );
};

export default AdminTestBar;
