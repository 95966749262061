import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  List,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  CalendarMonthRounded,
  CampaignRounded,
  ChatRounded,
  EmailRounded,
  LogoutRounded,
  MapRounded,
  MedicalInformationRounded,
  PeopleRounded,
  QuestionAnswerRounded,
  ScheduleRounded,
  SettingsRounded,
  SmsRounded,
  VerifiedRounded,
} from "@mui/icons-material";
import NavListItem from "components/common/NavListItem";
import { ChatContext } from "context/Chat";
import { useChat } from "components/chatbot/ContextBox/useChat";

export const OnboardingDrawer = ({ logout }) => (
  <List>
    <NavListItem icon={<MapRounded />} text="Return to Map" link="/" />
    <NavListItem icon={<LogoutRounded />} text="Log Out" onClick={logout} />
  </List>
);

export const PatientDrawer = ({ subscription, userData }) => {
  const navigate = useNavigate();
  const { newThread } = useContext(ChatContext);
  const { messages } = useChat();
  const disabled = !messages || messages?.length < 1;
  const [showNoEmail, setShowNoEmail] = useState(false);

  const handleClose = () => setShowNoEmail(false);

  const copyChat = async () => {
    try {
      const messagesArray = messages.map(
        (message) =>
          `${message.sender === "user" ? "You" : "HLTHDSK"}:\n${
            message.message
          }`
      );
      await navigator.clipboard.writeText(messagesArray.join("\n\n"));
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const sendEmail = () => {
    if (disabled) return;

    const messagesArray = messages.map(
      (message) =>
        `${message.sender === "user" ? "You" : "HLTHDSK"}:\n${message.message}`
    );

    window.open(
      "mailto:?subject=HealthDesk Medical SuperIntelligence Transcript&body=" +
        encodeURIComponent(messagesArray.join("\n\n"))
    );

    let t = setTimeout(() => setShowNoEmail(true), 500);
    window.addEventListener("blur", () => clearTimeout(t));
  };

  const navigateNewThread = () => {
    if (disabled) return;
    navigate("/dashboard");
    newThread();
  };

  return (
    <>
      <EmailDialog open={showNoEmail} onClose={handleClose} onCopy={copyChat} />
      <List>
        <NavListItem
          icon={<QuestionAnswerRounded />}
          text="Medical SuperIntelligence"
          secondary={!subscription ? "Free Limited Use" : "Member Access"}
          link={userData?.role === "facility" ? "/dashboard/msi" : "/dashboard"}
        />

        {!subscription && (
          <NavListItem
            icon={<QuestionAnswerRounded />}
            text="Medical SuperIntelligence"
            secondary="Member Access"
            disabled={!subscription}
            link={
              userData?.role === "facility" ? "/dashboard/msi" : "/dashboard"
            }
          />
        )}
        {/* <NavListItem
          icon={<ChatRounded />}
          text="New Chat"
          onClick={navigateNewThread}
          disabled={disabled}
        />
        <NavListItem
          icon={<EmailRounded />}
          text="Send Chat by Email"
          onClick={sendEmail}
          disabled={disabled}
        /> */}
      </List>
      <Divider />
    </>
  );
};

export const SettingsDrawer = ({ activeSubscriptionRole, userData }) => (
  <>
    <List>
      {!activeSubscriptionRole && (
        <NavListItem
          icon={<MedicalInformationRounded />}
          text="Upgrade to HLTHDSK Plus"
          link="/dashboard/upgrade"
        />
      )}
      <NavListItem
        icon={<SettingsRounded />}
        text="Settings"
        link="/dashboard/account"
      />
      {userData?.admin && (
        <NavListItem
          icon={<VerifiedRounded />}
          text="Location Approval"
          link="/dashboard/admin/approval"
        />
      )}
    </List>
    <Divider />
  </>
);

export const FacilityDrawer = () => (
  <>
    <List>
      <NavListItem
        icon={<ScheduleRounded />}
        text="Status Board"
        link="/dashboard"
      />
      <NavListItem
        icon={<CalendarMonthRounded />}
        text="Schedule"
        link="/dashboard/schedule"
      />
      <NavListItem
        icon={<PeopleRounded />}
        text="Virtual Queue"
        link="/dashboard/queue"
      />
      <NavListItem
        icon={<SmsRounded />}
        text="Healthcare Texts"
        link="/dashboard/sequence"
      />
      <NavListItem
        icon={<CampaignRounded />}
        text="Advertise"
        link="/dashboard/advertising"
      />
    </List>
    <Divider />
  </>
);

const EmailDialog = ({ open, onClose, onCopy }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>We weren't able to open your email client.</DialogTitle>
    <DialogContent>
      <DialogContentText>
        It looks like you don't have a default email client selected. If you'd
        like to send your chat transcript by email, please copy it to your
        clipboard using the button below.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onCopy}>
        Copy Chat Transcript
      </Button>
      <Button onClick={onClose} autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
