import firebaseApp, { db } from "services/firebase";
import LinkedInTag from "react-linkedin-insight";

export const createProfessionalUser = async (email, password, userData) => {
  try {
    // Create the Firebase auth user first
    const { user } = await firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password);

    // Now save the user data using the auth UID
    const batch = db.batch();
    const userRef = db.collection("users").doc(user.uid);

    batch.set(userRef, {
      uid: user.uid,
      email: userData.email,
      name: userData.name,
      lastName: userData.lastName,
      role: "professional",
    });

    await batch.commit();
    await user.sendEmailVerification();

    LinkedInTag.track(2177722);

    return user;
  } catch (error) {
    throw error;
  }
};
