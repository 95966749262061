import React from "react";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import DashboardLayout from "components/dashboard/DashboardLayout";
import DashboardRoutes from "routes/DashboardRoutes";
import PatientRoutes from "routes/PatientRoutes";
import EmailConfirmation from "components/EmailConfirmation";
import { ChatProvider } from "context/Chat";

const DashboardPage = () => {
  const { user, userLoading, userData } = useAuth();

  if (user === null) {
    return <Navigate to="/login/" />;
  }

  if (!user || userLoading) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Email verification check
  if (!user.emailVerified) {
    return (
      <DashboardLayout>
        <EmailConfirmation />
      </DashboardLayout>
    );
  }

  // Role loading check
  if (!userData?.role) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <CircularProgress color="primary" size={30} />
      </Box>
    );
  }

  return (
    <ChatProvider>
      {userData?.role === "patient" || userData?.role === "professional" ? (
        <PatientRoutes />
      ) : (
        <DashboardRoutes />
      )}
    </ChatProvider>
  );
};

export default DashboardPage;
